html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 / 400% 400%;
  animation: 30s infinite gradient;
}

body > div {
  width: 100%;
  height: 100%;
  background-image: url("pixels.af5f9bd0.svg");
  background-size: 40px;
  animation: 30s linear infinite pattern;
}

body.animation-disabled, body.animation-disabled > div {
  animation-play-state: paused;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes pattern {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 99%;
  }
}

@font-face {
  font-family: Lato-Bold;
  src: url("Lato-Bold.f53d8879.ttf") format("truetype");
}

html, body {
  width: 100%;
  height: 100%;
}

body > div {
  color: #fff;
  text-align: center;
  text-transform: lowercase;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Lato-Bold, sans-serif;
  font-size: 1.5em;
  line-height: 1;
  display: flex;
}

.hero-img, img {
  width: 150px;
  height: 150px;
  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
  background: #fff;
}

img {
  width: 142px;
  height: 142px;
  margin-top: 4px;
}

h1, p {
  margin: 0 0 .2em;
}

ul {
  clip-path: polygon(0% 0%, 8% 50%, 0% 100%, 100% 100%, 92% 50%, 100% 0%);
  border: 4px solid #fff;
  margin: .5em 0;
  padding: .15em .5em 0;
  list-style: none;
}

.link {
  color: inherit;
  text-transform: lowercase;
  width: auto;
  cursor: pointer;
  width: 7em;
  background: none;
  border: 2px solid #0000;
  margin-bottom: .5em;
  padding: 0 0 .1em;
  line-height: 1;
  text-decoration: none;
  transition: letter-spacing .2s, border .2s;
  display: block;
  overflow: visible;
}

.link:hover {
  letter-spacing: 1px;
}

.link:after {
  opacity: 0;
  content: "⇨";
  margin: .38em .3em;
  font-size: .6em;
  transition: opacity 1s;
  position: absolute;
}

.link:hover:after {
  opacity: 1;
}

.link:focus {
  border: 2px solid #fff;
  outline: none;
}

#pause-button {
  color: inherit;
  width: auto;
  cursor: pointer;
  background: none;
  border: 2px solid #fff3;
  font-size: .5em;
  line-height: 1;
  transition: border .3s, background-color .3s;
  position: absolute;
  bottom: 5px;
  right: 5px;
  overflow: visible;
}

#pause-button:hover {
  background-color: #0003;
  border: 2px solid #fff6;
}

#pause-button:focus {
  border: 2px solid #fff;
  outline: none;
}

/*# sourceMappingURL=index.8c2fc865.css.map */
